import { useState } from 'react';
import { Globals, handleWebserviceError } from '../../Helpers/Functions';
import { fetchWithTimeout as fetch } from "../../Helpers/Functions";

function useAPI() {
    const [apiResult, setApiResult] = useState({ data: null, type: '' });
    let qr_filename = '';
    let action_files = ['DOWNLOAD_FILE'];
    function callAPI(action, body) {
        if(action.requiresAuth) {
            action.headers['Authorization'] = `token ${Globals.token}`;
        }

        return fetch(process.env.REACT_APP_ENDPOINT + action.path, {
            method: action.method,
            headers: action.headers,
            body: JSON.stringify(body)
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(response.statusText);
                } else {
                    if (action_files.includes(action.type) && response.headers.get('content-disposition') !== null) {
                        const contentDisposition = response.headers.get('content-disposition');
                        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                        const matches = filenameRegex.exec(contentDisposition);

                        if (matches != null && matches[1]) {
                            qr_filename = matches[1].replace(/['"]+/g, '');
                        }

                        return response.blob();
                    } else if (response.status === 204) {
                        return null;
                    } else {
                        return response.json();
                    }
                }
            })
            .then(data => {
                if(data === undefined) {
                    throw new Error('Network request failed');
                }
                if (action_files.includes(action.type) && qr_filename !== '') {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', qr_filename);
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                } else {
                    setApiResult({ data, type: action.type });
                }
            })
            .catch(reason => {
                if(action.type === 'LOGIN') {
                    return setApiResult({ data: { status_code: 400 }, type: action.type });
                } else if(action.type === 'CHANGE_ZONE_SETTINGS') {
                    return setApiResult({ data: reason, type: action.type });
                } else if(action.type === 'GET_NOTIFICATIONS_ARCHIVER' || action.type === 'GET_NOTIFICATIONS') {
                    return setApiResult({ data: { status_code: 404 }, type: action.type });
                }
                handleWebserviceError(reason);
            });
    };
    return { callAPI, apiResult };
};

export default useAPI;

import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom'
import { handleWebserviceError } from '../Helpers/Functions';
import highchartsMore from 'highcharts/highcharts-more.js';
import solidGauge from 'highcharts/modules/solid-gauge.js';
import DashboardKpi from '../Components/DashboardKpi';
import Loading from '../Components/Loading';
import '../Styles/ModuleDashboard.css';
import ChartPower from '../Components/ChartPower';
import Highcharts from 'highcharts/highcharts.js';
import { withTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import { GET_CHART_ENERGY } from '../hooks/useAPI/actions';
import useAPI from '../hooks/useAPI';
import Navigation from '../Components/Navigation';
import UtilsContext from '../Context/utilsContext';
import moment from 'moment';

highchartsMore(Highcharts);
solidGauge(Highcharts);

window.zonesInterval = null;
function PowerDashboard({ t }) {
    const { customers } = useContext(UtilsContext);
    const [powerDashboardData, powerZoneDashboardData] = useState({ date: new Date()});
    const { moduleId } = useParams();
    const [chartData, setChartData] = useState({ data: {}, moduleId });
    const [buildingInfo, setbuildingInfo] = useState({});
    const history = useHistory();
    const { callAPI, apiResult } = useAPI();
    const { data, type } = apiResult;
    const location = useLocation()

    useEffect(() => {
        window.clearInterval(window.tempInterval);
        if(moduleId !== undefined) {
            callAPI(GET_CHART_ENERGY(moduleId, `${powerDashboardData.date.getFullYear()}-${powerDashboardData.date.getMonth() + 1}-${powerDashboardData.date.getDate()}`));
            window.tempInterval = window.setInterval(() => {
                callAPI(GET_CHART_ENERGY(moduleId, `${powerDashboardData.date.getFullYear()}-${powerDashboardData.date.getMonth() + 1}-${powerDashboardData.date.getDate()}`));
            }, 300000);
        } else {
            setChartData({ data: {}, moduleId });
        }
    }, [powerDashboardData.date]);

    useEffect(() => {
        setChartData({ data: {}, moduleId });
    }, [moduleId]);

    useEffect(() => {
        if (customers.buildings) {
            let current_building_info = customers.buildings.find(building =>
                building.zones.flatMap(zone => zone.modules).some(module => module.id === parseInt(moduleId))
            );
            setbuildingInfo(current_building_info);
        }
    }, [customers])

    useEffect(() => {
        if(data != null && data.status_code !== undefined) {
            handleWebserviceError(data);
        } else if (data != null && data.status_code === undefined && type === 'GET_CHART_ENERGY') {
            setChartData({ ...chartData, data: data });
        }
    }, [data]);

    var content;
    if (data === null || customers.buildings === undefined) {
        content = (
            <Loading></Loading>
        );
    } else if (moment(buildingInfo.subscription_expiry_date, "YYYY-MM-DD") < moment().startOf('day')) {
        history.push('/building/' + buildingInfo.id)
    } else {
        content = (
            <main role='main' className='col-12 main-dashboard'>
                <Navigation
                    date={powerDashboardData.date}
                    items={[location.state]}
                    setDate={(date) => powerZoneDashboardData({ ...powerDashboardData, date })}
                />
                {(data !== null && !("error" in data))? (<div className='info-sidebar energy-kpi'>
                <DashboardKpi
                    power={true}
                    kpis={{'price': data.price, 'total_active_energy': data.total_active_energy, 'max': data.max_power}}
                />
                </div>) : false}
                <div>
                    <ChartPower date={powerDashboardData.date} moduleId={moduleId}></ChartPower>
                </div>
            </main>
        );
    }

    return content;
}

export default withTranslation()(PowerDashboard);

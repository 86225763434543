import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import Navigation from '../Components/Navigation';
import ZoneCard from '../Components/ZoneCard';
import { useHistory, useParams, Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { classList} from '../Helpers/Functions';
import { ModuleTypes } from '../Helpers/ModuleTypes';
import UtilsContext, { ADMIN_USERS_ID } from '../Context/utilsContext';
import DashboardKpi from '../Components/DashboardKpi';
import Loading from '../Components/Loading';
import { GET_CHART_ENERGY, UPDATE_SUBSCRIPTION_DATE } from '../hooks/useAPI/actions';
import useAPI from '../hooks/useAPI';
import EdiText from 'react-editext'
import styled from 'styled-components'

const StyledEdiText = styled(EdiText)`
    button {
        border-radius: 5px;
        padding: 0;
        margin: 0;
    }
    input, textarea {
        padding: 10px 15px;
        border-radius: 5px;
        height: 2.45rem;
    }
`

let kpiCalls = 0
function Building({ i18n, t }) {
    const [buildingData, setBuildingData] = useState({ calledCO2Kpis: false, modules: [], name: '', zonesCO2Kpis: [], building: {}});
    const [sortType, setSortType] = useState(() => {
        return localStorage.getItem('sortType');
    });
    const [chartEnergyData, setChartEnergyData] = useState();
    const [sortIconAlphaAscending, setSortIconAlphaAscending] = useState(undefined);
    const [sortIconNumAscending, setSortIconNumAscending] = useState(undefined);
    const [ dropdownStatus, setDropdownStatus ] = useState(false)
    const { building } = useParams();
    const history = useHistory();
    const { customers, alerts, setCustomers, updateSubscriptionExpiryDate } = useContext(UtilsContext);
    const { callAPI, apiResult } = useAPI();
    const { data, type } = apiResult;
    const current_date = moment().format('YYYY-M-D');

    function setSortIconAlpha() {
        let nextSortType = sortType === "name" ? "-name" : "name";
        setSortIconAlphaAscending(!sortIconAlphaAscending)
        setSortIconNumAscending(undefined)
        setSortType(nextSortType);
        localStorage.setItem('sortType', nextSortType);
    }

    function setSortIconNum() {
        let nextSortType = sortType === "count" ? "-count" : "count";
        setSortIconNumAscending(!sortIconNumAscending)
        setSortIconAlphaAscending(undefined)
        setSortType(nextSortType);
        localStorage.setItem('sortType', nextSortType);
    }

    function getAlertsCountByZoneId(zoneId) {
        if (alerts && alerts.zones) {
            var zonesAlerts = alerts.zones.find(function (alert) {
                return alert.zone === zoneId;
            });
            if (zonesAlerts) {
                return zonesAlerts.alerts_count || 0;
            }
        }
        return 0;
    }

    useEffect(() => {
        if(customers.length !== 0) {
            let selectedBuilding = null;
            let modules = [];
            let energy_module
            customers.buildings.forEach(customerBuilding => (customerBuilding.id === parseInt(building)) ? selectedBuilding = customerBuilding : false);

            selectedBuilding.zones.forEach(zone => {
                let CO2Module

                zone.modules.forEach(module => {
                    if (module.module_type === ModuleTypes.Energy) {
                        energy_module = module
                    }
                    if (module.module_type === ModuleTypes.CO2) {
                        CO2Module = module
                    }});

                if(CO2Module !== undefined) {
                    CO2Module.name = zone.name;
                    CO2Module.zone_id = zone.id;
                    modules.push(CO2Module);
                }
            });
            setBuildingData({ ...buildingData, modules, name: selectedBuilding.name,  energy_module, building: selectedBuilding});
        }
    }, [building, customers]);

    useEffect(() => {
        if (buildingData.energy_module) {
            callAPI(GET_CHART_ENERGY(buildingData.energy_module.id, current_date));
        }
    }, [buildingData.energy_module])

    useEffect(() => {
        if (type === 'GET_CHART_ENERGY') {
            setChartEnergyData(data)
        }
    }, [data])

    useEffect(() => {
        if (buildingData.calledCO2Kpis && !buildingData.zonesCO2Kpis.length) {
            history.push('/');
        }
    }, [buildingData.calledCO2Kpis])

    useEffect(() => {
        const sortedModules = sortType ? [...buildingData.modules].sort(dynamicSort(sortType)) : buildingData.modules;

        if (!buildingData.modules.every((module, index) => module === sortedModules[index])) {
            setBuildingData({
                ...buildingData,
                modules: sortedModules
            });
        }
    }, [buildingData.modules, sortType]);


    const setCurrentKpi = (data) => {
        kpiCalls++
        let currentKpis = buildingData.zonesCO2Kpis;
        if(data.error === undefined) {
            currentKpis.push(data);
        }
        setBuildingData({ ...buildingData, calledCO2Kpis: kpiCalls === buildingData.modules.length, zonesCO2Kpis: currentKpis });
    }

    function dynamicSort(property = 'name') {
        let sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }

        return function (a, b) {
            const valueA = a[property];
            const valueB = b[property];

            let result = 0;

            if (valueA == null && valueB != null) {
                result = -1;
            } else if (valueA != null && valueB == null) {
                result = 1;
            } else if (valueA != null && valueB != null) {
                if (typeof valueA === "number" && typeof valueB === "number") {
                    result = valueA - valueB;
                } else if (typeof valueA === "string" && typeof valueB === "string") {
                    result = valueA.localeCompare(valueB, undefined, { numeric: true, sensitivity: 'base' });
                } else {
                    result = String(valueA) < String(valueB) ? -1 : String(valueA) > String(valueB) ? 1 : 0;
                }
            }

            return result * sortOrder;
        };
    }

    function changeSubscriptionDate(new_date) {
        updateSubscriptionExpiryDate(buildingData.building.id, new_date);
        callAPI(UPDATE_SUBSCRIPTION_DATE, {'subscription_expiry_date_date': new_date, 'building_id': buildingData.building.id});
    }

    if(buildingData.modules.length === 0 && buildingData.calledCO2Kpis) {
        history.push('/');
        return null;
    } else if (moment(buildingData.building.subscription_expiry_date, "YYYY-MM-DD") < moment().startOf('day')) {
        const lines = t('common.expiration_info_second').split('\n');
        return (
            <div className='expiration_info'>
                <div className='expiration_info_title'>{t('common.expiration_info_title')}</div>
                <br/>
                <div className='expiration_info_first'>{t('common.expiration_info_first', {building_name: buildingData.building.name})}</div>
                <div>{t('common.expiration_info_expiration_date')}
                    <div className='expiration_info_expiration_date'>
                        {
                            (customers.user in ADMIN_USERS_ID) ? (
                                <StyledEdiText
                                    type='date'
                                    value={moment(buildingData.building.subscription_expiry_date, "YYYY-MM-DD").format('L')}
                                    onSave={v => changeSubscriptionDate(v)}
                                />
                            ) : (moment(buildingData.building.subscription_expiry_date, "YYYY-MM-DD").format('L'))
                        }
                    </div>
                </div>
                <br/>
                <div className='expiration_info_second'>
                    <div>{lines[0]} {lines[1]}</div>
                    <ul className='expiration_info_first'>
                        {lines.slice(2).map((line, index) => (
                            <li key={index}>{line}</li>
                        ))}
                    </ul>
                </div>
                <br/>
                <br/>
                <div className='expiration_info_third'>{t('common.expiration_info_third')}</div>
            </div>
        )
    } else if (buildingData.modules.length > 0) {
        return(
            <div className="building">
                <Navigation
                    items={[buildingData.name]}
                    id={building}
                    setCustomers={setCustomers}
                    getCustomers={customers}
                />
                {(buildingData.energy_module !== undefined && chartEnergyData !== undefined && chartEnergyData !== null && !("error" in chartEnergyData)) ? (<div className='info-sidebar energy-kpi energy-kpi-building'>
                    <Link
                            to={{pathname: `/energy/${buildingData.energy_module.id}`,
                                state: buildingData.name}}
                        >
                        <DashboardKpi
                            power={true}
                            kpis={{'price': chartEnergyData.price, 'total_active_energy': chartEnergyData.total_active_energy, 'max': chartEnergyData.max_power}}
                        />
                    </Link>
                </div>) : false}

                <div className="dropdown-sort">
                    <button onClick={() => setDropdownStatus(!dropdownStatus)} className="dropbtn-sort">
                        <div className="dropdown-building-button-sort">
                        {t(`sort.title`)}
                        <i className="ico ico--sort" />
                        </div>
                    </button>
                    <div className={classList({
                        'dropdown-content-sort': true,
                        'show': dropdownStatus
                    })}>
                        <div onClick={() => setSortIconAlpha()} className="dropdown-row-sort">{t('sort.name')} <i className={classList({
                            "ico ico--sort-solid": (sortIconAlphaAscending === undefined),
                            "ico ico--sort-ascending": (sortIconAlphaAscending !== undefined && sortIconAlphaAscending),
                            "ico ico--sort-descending": (sortIconAlphaAscending !== undefined && !sortIconAlphaAscending)
                        })} /></div>
                        <div onClick={() => setSortIconNum()} className="dropdown-row-sort">{t('sort.alerts')} <i className={classList({
                            "ico ico--sort-solid": (sortIconNumAscending === undefined),
                            "ico ico--sort-ascending": (sortIconNumAscending !== undefined && sortIconNumAscending),
                            "ico ico--sort-descending": (sortIconNumAscending !== undefined && !sortIconNumAscending)
                        })} /></div>
                    </div>
                </div>

                <div className="building-zones">
                {
                    (buildingData.modules.length > 0) ? (buildingData.modules.map((_module, index) => (
                        <Link
                        className='navbar-bell'
                        to={`/dashboard/${_module.zone_id}`}>
                            <ZoneCard key={`zoneCard${index}`} mode="card" module={_module} zone_alerts={getAlertsCountByZoneId(_module.zone_id)} setCurrentKpi={setCurrentKpi}/>
                        </Link>
                    ))) : false
                }
                </div>
            </div>
        )
    } else {
        return <Loading></Loading>;
    }
}

export default withTranslation()(Building);

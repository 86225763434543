import React, { useContext, useEffect, useState } from 'react';
import { handleWebserviceError } from '../Helpers/Functions';
import highchartsMore from 'highcharts/highcharts-more.js';
import solidGauge from 'highcharts/modules/solid-gauge.js';
import DashboardKpi from '../Components/DashboardKpi';
import Loading from '../Components/Loading';
import '../Styles/ModuleDashboard.css';
import ChartTemperature from '../Components/ChartTemperature';
import Highcharts from 'highcharts/highcharts.js';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import { GET_ZONE_KPIS } from '../hooks/useAPI/actions';
import useAPI from '../hooks/useAPI';
import Navigation from '../Components/Navigation';
import ChartLighting from '../Components/ChartLighting';
import UtilsContext from '../Context/utilsContext';
import Co2Chart from '../Components/Co2Chart';
import ChartOccupation from '../Components/ChartOccupation';
import moment from 'moment';

highchartsMore(Highcharts);
solidGauge(Highcharts);

window.zonesInterval = null;
function ZoneDashboard({ t }) {
    const { customers, setCustomers } = useContext(UtilsContext);
    const [zoneDashboardData, setZoneDashboardData] = useState({ date: new Date(), dashboard: {}, dashboard_error: null, kpis: {} });
    const { callAPI, apiResult } = useAPI();
    const { zone } = useParams();
    const history = useHistory();
    const { data, type } = apiResult;

    useEffect(() => {
        if(customers.length !== 0) {
            let dashboard = null;
            customers.buildings.forEach(building => building.zones.forEach(buildingZone => (buildingZone.id === parseInt(zone)) ? dashboard = { ...buildingZone, building } : null));
            setZoneDashboardData({ ...zoneDashboardData, date: new Date(), dashboard });
        }
    }, [customers, zone]);

    useEffect(() => {
        window.clearInterval(window.zonesInterval)
        callAPI(GET_ZONE_KPIS(zone, `${zoneDashboardData.date.getFullYear()}-${zoneDashboardData.date.getMonth() + 1}-${zoneDashboardData.date.getDate()}`));
        window.zonesInterval = window.setInterval(() => {
            callAPI(GET_ZONE_KPIS(zone, `${zoneDashboardData.date.getFullYear()}-${zoneDashboardData.date.getMonth() + 1}-${zoneDashboardData.date.getDate()}`));
        }, 300000);
    }, [zone, zoneDashboardData.date])

    useEffect(() => {
        if(data != null && data.status_code !== undefined) {
            handleWebserviceError(data);
        } else if (data != null && data.status_code === undefined && type === 'GET_ZONE_KPIS') {
            setZoneDashboardData({ ...zoneDashboardData, kpis: data.kpis });
        }
    }, [data]);

    var content;
    if (zoneDashboardData.dashboard === undefined) {
        content = (
            <Loading></Loading>
        );
    } else if (
        zoneDashboardData.dashboard.id === undefined ||
        !('id' in zoneDashboardData.dashboard)
    ) {
        content = (
            <Loading></Loading>
        );
    } else if (moment(zoneDashboardData.dashboard.building.subscription_expiry_date, "YYYY-MM-DD") < moment().startOf('day')) {
        history.push('/building/' + zoneDashboardData.dashboard.building.id)
    } else {
        content = (
            <main role='main' className='col-12 main-dashboard'>
                <Navigation
                    action={t('common.preferences')}
                    actionIcon={<FontAwesomeIcon icon={faCog} />}
                    actionUrl={`/settings/${zoneDashboardData.dashboard.id}`}
                    date={zoneDashboardData.date}
                    items={[zoneDashboardData.dashboard.building.name, zoneDashboardData.dashboard.name]}
                    setDate={(date) => setZoneDashboardData({ ...zoneDashboardData, date })}
                    id={zone}
                    setCustomers={setCustomers}
                    getCustomers={customers}
                />
                <div className='info-sidebar'>
                    <DashboardKpi
                        current_day_target={zoneDashboardData.current_day_target}
                        dashboard={zoneDashboardData.dashboard}
                        kpis={zoneDashboardData.kpis}
                    />
                </div>
                <div className='module-charts'>
                    <ChartTemperature date={zoneDashboardData.date} modules={zoneDashboardData.dashboard.modules}></ChartTemperature>
                    <ChartLighting date={zoneDashboardData.date} modules={zoneDashboardData.dashboard.modules}></ChartLighting>
                    <Co2Chart date={zoneDashboardData.date} modules={zoneDashboardData.dashboard.modules}></Co2Chart>
                    <ChartOccupation date={zoneDashboardData.date} modules={zoneDashboardData.dashboard.modules}></ChartOccupation>
                </div>
            </main>
        );
    }

    return content;
}

export default withTranslation()(ZoneDashboard);

import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts/highcharts.js';
import HighchartsReact from 'highcharts-react-official';
import HighchartsHeatmap from 'highcharts/modules/heatmap';
import { withTranslation } from 'react-i18next';
import { handleWebserviceError } from '../Helpers/Functions';
import { GET_CO2_CHART } from '../hooks/useAPI/actions';
import useAPI from '../hooks/useAPI';
import { ModuleTypes } from '../Helpers/ModuleTypes';

HighchartsHeatmap(Highcharts);

const COLORS = [
    ["#F6FFFE", "#E9FFFB", "#D5FFF7", "#C1FFF3", "#A9FFEF", "#9FFFED", "#7DFFE6", "#5BFFDF", "#3AFFD8", "#1BFFD2", "#00FFCC"],
    ["#fe928f","#FF7975", "#FF6863", "#FF534D", "#FF3B34", "#db6666", "#d85555", "#d44343", "#cf2f2f", "#b82a2a", "#9F2525"],
    ["#FFFEEB", "#FFFDC2", "#FFFC98", "#FFFB86", "#FFFA73", "#FFF961", "#FFF843", "#d7ad36", "#cca128", "#b48e24", "#99791F"]
]

window.co2Interval = null;
function Co2Chart({ date, modules, t }) {
    let module = modules.find(module => (module.module_type === ModuleTypes.CO2));
    const [chartData, setChartData] = useState({ highcharts: undefined, module });
    const { callAPI, apiResult } = useAPI();
    const { data, type } = apiResult;

    useEffect(() => {
        if(chartData.module !== undefined) {
            window.clearInterval(window.co2Interval);
            callAPI(GET_CO2_CHART(chartData.module.id, `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`));
            window.co2Interval = window.setInterval(() => {
                callAPI(GET_CO2_CHART(chartData.module.id, `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`));
            }, 300000);
        } else {
            setChartData({ highcharts: {}, module });
        }
    }, [chartData.module, date]);

    useEffect(() => {
        module = modules.find(module => (module.module_type === ModuleTypes.CO2));
        setChartData({ highcharts: undefined, module });
    }, [modules]);

    useEffect(() => {
        if(data != null && data.status_code !== undefined) {
            handleWebserviceError(data);
        } else if (data != null && data.status_code === undefined && type === 'GET_CO2_CHART') {
            setChartData({ ...chartData, highcharts: data });
        }
    }, [data]);

    function generateCO2Bars() {
        let bars = [];

        chartData.highcharts.air_quality.map(function(aiq, index) {
            let roundedPerc = Math.round(aiq/10)*10;
            let c = (roundedPerc >= chartData.highcharts.mid_threshold) ? 0 : (roundedPerc >= chartData.highcharts.low_threshold) ? 2 : 1;

            let keys = Object.keys(chartData.highcharts);
            let unusedKeys = ["air_quality", "mid_threshold", "low_threshold", "last_values"];
            let res = keys.filter(item => !unusedKeys.includes(item));

            let keysDict = {};
            res.forEach(createCustomDict);
            function createCustomDict(item) {
                keysDict[item] = chartData.highcharts[item][index]
            }

            for(let y = 0; y < 10; y++) {
                bars.push({
                    custom : keysDict,
                    color: (aiq === null) ? '#ffffff' : ((y * 10 <= roundedPerc) ? COLORS[c][y] : '#ffffff'),
                    x: index,
                    tooltip: (aiq === null) ? false : (y === 0),
                    y,
                });
            }
            return false;
        });
        return bars;
    }

    if(chartData.highcharts !== undefined) {
        var co2Chart = {
            chart: {
                plotBackgroundColor: '#F7F7F7',
                type: 'heatmap'
            },
            legend: false,
            plotOptions: {
                series: {
                    point: {
                        events: {
                            mouseOver: function () {
                                if(this.tooltip) {
                                    this.options.oldColor = this.color;
                                    this.graphic.attr("fill", "#333333");
                                }

                                return false;
                            },
                            mouseOut: function () {
                                if(this.tooltip) {
                                    this.graphic.attr("fill", this.options.oldColor);
                                }

                                return false;
                            }
                        }
                    }
                }
            },
            series: [{
                borderColor: '#fff',
                borderWidth: 1,
                data: generateCO2Bars(),
                states: { hover: { enabled: false } }
            }],
            title: false,
            tooltip: {
                formatter: function(tooltip) {
                    if(this.point.tooltip) {
                        let htmlTooltip =  `<table><tr><small>${this.point.custom.date}h</small></tr></br>`;

                        for (let k in this.point.custom) {
                            if (k !== "date" && this.point.custom[k] !== null) {
                                htmlTooltip += `<tr>${t('common.'+ k +'_value')}: <b>${this.point.custom[k]} ${t('common.'+ k +'_unit')}</b></tr></br>`;
                            }
                        }
                        return htmlTooltip;
                    }

                    return false;
                },
                useHTML: true
            },
            xAxis: {
                categories: chartData.highcharts.date
            },
            yAxis: {
                categories: ['0', '10', '20', '30', '40', '50', '60', '70', '80', '90'],
                labels: {
                    align: 'right',
                    y: 20
                },
                plotLines: [{
                    color: 'transparent',
                    dashStyle: 'solid',
                    label: {
                        style: {
                            color: '#666666',
                            fontSize: '11px'
                        },
                        text: '100',
                        x: -35,
                        y: -14
                    },
                    value: 9,
                    width: 1,
                    zIndex: 999
                  }],
                title: {
                    text: `${t('common.air_quality_perc')} (%)`
                }
            }
        }
    }

    if(chartData.highcharts !== undefined && co2Chart !== undefined && chartData.highcharts.air_quality.length > 0) {
        return (
            <div>
                <div className='chart-container' id='co2-chart'>
                    <h6 className='chart-title pt-3 ml-3'>{t('common.air_quality_perc')}</h6>
                    <HighchartsReact
                        style={{ height: 'calc(100% - 42px)' }}
                        highcharts={Highcharts}
                        options={co2Chart}
                    />
                </div>
            </div>
        )
    } else {
        return false;
    }
}

export default withTranslation()(Co2Chart);
